import React from "react";
import { SEO } from "../components/SEO";

const Impressum = (): React.ReactElement => {
  return (
    <>
      <SEO title="Impressum" metaDescription="Impressum" />

      <div className="m-3">
        <h2 className="text-3xl">Anschrift</h2>
        <p>
          Kim Ohlsen
          <br /> Schiftenweg 1<br /> 24887 Silberstedt / Hünning
          <br />
          <b>Telefon</b> 04625 / 82 26 858
          <br />
          <b>Web</b>{" "}
          <a href="http://www.schmiedekunst-ohlsen.com/">
            www.schmiedekunst-ohlsen.com
          </a>
          <br />
          <b>E-Mail</b>{" "}
          <a href="mailto:info@schmiedekunst-ohlsen.com">
            info@schmiedekunst-ohlsen.com
          </a>
        </p>
        <h2 className="text-3xl">Datenschutzerklärung Allgemein</h2>
        <p>
          In dieser Datenschutzerklärung erfahren Sie, in welcher Weise, zu
          welchem Zweck und in welchem Maße wir Ihre personenbezogenen Daten
          erheben und nutzen. <br />
          <br />
          Wir halten bei jeder Erhebung und Nutzung Ihrer personenbezogenen
          Daten die gesetzlichen Vorschriften der Bundesrepublik Deutschland
          ein. <br />
          <br />
          <strong>Was versteht man unter personenbezogenen Daten?</strong>
          <br />
          <br />
          Â§ 3 Abs. 1 Bundesdatenschutzgesetz definiert personenbezogene Daten
          als Einzelangaben über persönliche oder sachliche Verhältnisse einer
          bestimmten oder bestimmbaren natürlichen Person. Darunter versteht man
          zum Beispiel Ihren Namen, Ihre Postanschrift, Ihre Email-Adresse und
          Telefonnummer sowie die IP-Adresse, mit der sich Ihr Computer
          identifizieren lässt. <br />
          <br />
          <strong>Nutzungsdaten</strong>
          <br />
          <br />
          Sobald Sie auf unsere Webseite zugreifen, beginnt Ihr Webbrowser aus
          technischen Gründen damit, Daten an unseren Server zu übermitteln.
          Dazu gehören das Datum und Uhrzeit des Seitenaufrufs, die URL bzw.
          Webadresse der von Ihnen vorher aufgerufenen Seite, die Bezeichnungen
          der Dateien, welche Sie von unserer Seite abrufen und die an Ihren
          Rechner gesendeten Datenmengen. Ferner werden auch Ihr Typ und Version
          Ihres Betriebssystems, Ihr Browser und Ihre IP-Nummer übermittelt.
          Diese Daten können von uns verwendet werden, um Statistiken über die
          Nutzung unserer Internetseiten anzufertigen oder auch für Zwecke der
          Marktforschung. Wir können anhand der Daten auch Nutzerprofile
          erstellen. Der Erstellung solcher Profile können Sie widersprechen.{" "}
          <br />
          <br />
          Wir bringen die genannten Daten über die Nutzung unserer Seiten nicht
          mit Ihrer Person in Verbindung. Die aus technischen Gründen
          übermittelten Daten werden nicht mit irgendwelchen Angaben, die Sie
          persönlich bei der Nutzung unserer Seiten machen, in Verbindung
          gebracht und werden getrennt davon gespeichert. Falls Nutzerprofile
          erstellt werden, werden diese mit einem Pseudonym versehen und nicht
          mit anderen Daten über Ihre Person oder mit Ihren Eingaben auf unseren
          Seiten in Verbindung gebracht. <br />
          <br />
          <strong>Bestandsdaten / Kundendaten</strong>
          <br />
          <br />
          In der Regel erfordert die Nutzung unserer Internetseite nicht, dass
          Sie irgendwelche persönlichen Daten eingeben. Sollten Sie jedoch einen
          Vertrag mit uns abschließen oder Geschäftsbeziehungen aufnehmen,
          müssen Sie einige Angaben machen. Dies gilt auch bei einer Änderung
          bestehender Verträge. Wie erheben und nutzen diese personenbezogenen
          Daten nur in dem Umfang, der für den einzelnen Vertragsabschluss / die
          Vertragsänderung notwendig ist. Die personenbezogenen Daten werden
          nicht ohne Ihre ausdrückliche Zustimmung an Dritte weitergegeben. An
          Behörden geben wir die Daten allenfalls weiter, wenn wir dazu in einem
          einzelnen Fall aufgrund gesetzlicher Regelungen verpflichtet sind.{" "}
          <br />
          <br />
          <strong>Nutzung von Cookies</strong>
          <br />
          <br />
          Cookies sind kleine Textdateien, die auf Ihrem Rechner gespeichert
          werden. Diese enthalten Informationen darüber, wie Sie unsere
          Webseiten und das Internet nutzen. Cookies erlauben es, Sie beim
          nächsten Besuch unserer Internetpräsenz als einen Nutzer zu
          identifizieren, der sich für bestimmte Inhalte interessiert, der sich
          etwa über bestimmte Waren informiert hat. Mit diesen Informationen
          können wir die Nutzung unserer Seiten für Sie komfortabler gestalten
          und unser Internetangebot verbessern. Sie haben die Möglichkeit, das
          Abspeichern von Cookies auf Ihrem Computer durch eine Einstellung
          Ihres Browsers zu unterbinden. Dann kann es jedoch sein, dass Sie
          unsere Internetseiten nicht in vollem Umfang nutzen können. <br />
          <br />
          <strong>Ihre Rechte</strong>
          <br />
          <br />
          Sie können von uns jederzeit kostenfrei Auskunft darüber verlangen,
          welche Daten über Ihre Person oder ein von Ihnen verwendetes Pseudonym
          bei uns gespeichert sind, von wo die Daten stammen und wer sie erhält.
          Auch über den Zweck der Datenverarbeitung können Sie Auskunft
          verlangen. Sie haben außerdem das Recht auf Berichtigung, Löschung
          oder Sperrung Ihrer Daten. Bei Fragen zum Thema Datenschutz
          kontaktieren Sie uns bitte über die im Impressum genannte Adresse des
          Betreibers dieser Internetseite. <br />
          <br />
          <strong>Unbefugter Zugriff auf Daten durch Dritte</strong>
          <br />
          <br />
          Jede Übermittlung von Daten über das Internet beinhaltet das Risiko,
          dass sich unbefugte Fremde Zugriff auf Ihre Daten oder zum Beispiel
          den Inhalt von Emails verschaffen. Ein vollkommener Schutz ist auch
          bei aller Sorgfalt nicht möglich.
          <br />
          <br />
          <strong>Widerspruch gegen unerwünschte Werbung</strong>
          <br />
          <br />
          Wir widersprechen jeglicher Nutzung unserer Kontaktdaten durch Dritte
          zu dem Zweck, uns unverlangte Werbung in irgendeiner Form zuzusenden.
          Bei Zuwiderhandlung behalten wir uns rechtliche Maßnahmen gegen den
          Absender vor. .
        </p>
        <h2 className="text-3xl">Datenschutzerklärung Facebook</h2>
        <p>
          Auf unseren Internetseiten finden Sie Plugins des sozialen Netzwerks
          Facebook (Facebook Inc., 1601 Willow Road, Menlo Park, California,
          94025, U.S.A.). Diese Plugins sind leicht zu erkennen am Facebook-Logo
          oder am "Like-Button" ("Gefällt mir"). Eine Aufstellung der von
          Facebook genutzten Plugins finden Sie unter folgendem Link: <br />
          http://developers.facebook.com/docs/plugins/
          <br />
          <br />
          Sobald Sie eine unserer Webseiten mit einem solchen Plugin aufrufen,
          wird zwischen Ihrem Browser und den Servern von Facebook in den U.S.A.
          eine Verbindung hergestellt. Dies passiert auch, wenn Sie den Button
          nicht anklicken. Für Facebook wird dadurch erkennbar, dass von Ihrer
          IP-Adresse aus unsere Webseite aufgerufen wurde. Wir weisen darauf
          hin, dass wir weder Kenntnis darüber haben noch darauf einwirken
          können, welche Daten Facebook mit Hilfe der Plugins erhebt und wie und
          wofür diese genutzt werden. <br />
          <br />
          Wenn Sie den Button anklicken, während Sie in Ihrem Facebook-Account
          angemeldet sind, können Sie unsere Internetseiten mit Ihrem
          Facebook-Profil verlinken. Facebook kann dann erkennen, dass Sie
          unsere Webseiten nutzen, und kann Informationen über Ihr
          Nutzerverhalten sammeln. Möchten Sie dies verhindern, sollten Sie sich
          vor dem Öffnen unserer Internetseiten aus Ihrem Nutzerkonto bei
          Faceboook ausloggen. <br />
          <br />
          Die Erklärung von Facebook selbst zum Umgang mit personenbezogenen
          Daten finden Sie unter diesem Link: <br />
          https://www.facebook.com/about/privacy
        </p>
        <h2 className="text-3xl">Datenschutzerklärung Google Analytics</h2>
        <p>
          Diese Internetseite verwendet Google Analytics, einen Analysedienst
          der Google Inc. ("Google"), 1600 Amphitheatre Parkway, Mountain View,
          CA 94043, U.S.A. Im Rahmen von Google Analytics werden sogenannte
          Cookies verwendet. Dies sind kleine Textdateien, die auf Ihrem
          Computer gespeichert werden. Mit Hilfe von Cookies kann ausgewertet
          werden, wie Sie unsere Webseite nutzen. Die so gewonnenen Daten über
          Ihr Nutzerverhalten werden an einen Server von Google in den U.S.A.
          gesendet und dort gespeichert. <br />
          <br />
          <strong>Erfassung von Daten</strong>
          <br />
          <br />
          Auf dieser Webseite ist eine IP-Anonymisierung aktiviert. Daher wird
          Ihre IP-Adresse innerhalb von Mitgliedstaaten der Europäischen Union
          oder in anderen Vertragsstaaten des Abkommens über den Europäischen
          Wirtschaftsraum von Google vor der Übertragung in die U.S.A. gekürzt.
          Lediglich im Ausnahmefall kann es vorkommen, dass eine IP-Adresse in
          voller Länge an einen Server von Google in den U.S.A. übertragen und
          dort erst gekürzt wird. <br />
          <br />
          <strong>Nutzung und Speicherung von Daten </strong>
          <br />
          <br />
          Im Auftrag des Betreibers dieser Internetseite wird Google die
          gewonnenen Informationen über Ihr Nutzerverhalten dazu verwenden, um
          Ihre Nutzung der Website zu analysieren, um Reports über die
          Aktivitäten auf der Internetseite zu erstellen und um weitere mit der
          Seitennutzung und der Internetnutzung verbundene Dienstleistungen
          gegenüber dem Betreiber der Internetseite zu erbringen. Die mit Hilfe
          von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird
          nicht mit anderen Daten von Google zusammengeführt. <br />
          <br />
          <strong>
            Ihre Möglichkeiten zur Unterbindung der Datenerfassung
          </strong>
          <br />
          <br />
          Möchten Sie verhindern, dass mit Hilfe von Cookies Daten über Ihr
          Nutzerverhalten gesammelt werden, so können Sie die Speicherung der
          Cookies durch eine Browser-Einstellung verhindern. Allerdings ist es
          möglich, dass Sie in diesem Fall nicht unser komplettes
          Internetangebot nutzen können. <br />
          <br />
          Sie können zusätzlich auch die Erfassung der durch das Cookie
          erzeugten Daten über Ihre Nutzung der Internetseite und die
          Verarbeitung der Daten (inkl. Ihrer IP-Adresse) durch Google
          verhindern, indem Sie das unter dem folgenden Link verfügbare
          Browser-Plugin herunterladen und installieren:
          <br />
          http://tools.google.com/dlpage/gaoptout?hl=de
          <br />
          <br />
          Möchten Sie die Erfassung und Analyse Ihrer IP-Adresse durch Google
          Analytics unterbinden, klicken Sie bitte auf folgenden Link. Dadurch
          wird ein sogenannter â€žOpt-Out-Cookieâ€œ gesetzt, der eine künftige
          Erfassung Ihrer IP-Adresse durch Google Analytics auf dieser
          Internetseite verhindert. <br />
          Google Analytics deaktivieren
          <br />
          <br />
          Auf unserer Webseite haben wir Google Analytics um den Code
          â€žgat._anonymizeIp();â€œ erweitert. Dies gewährleistet eine verkürzte
          und damit anonymisierte Erfassung von IP-Adressen.
          <br />
          <br />
          <strong>Google-Informationen zum Datenschutz</strong>
          <br />
          <br />
          Google selbst gibt Ihnen als Nutzer Informationen über seine
          Nutzungsbedingungen und den dort praktizierten Datenschutz unter den
          folgenden Internetadressen:
          http://www.google.com/analytics/terms/de.html
          <br />
          https://www.google.de/intl/de/policies/
        </p>
        <h2 className="text-3xl">Datenschutzerklärung Google Adsense</h2>
        <p>
          Auf unserer Internetseite nutzen wir den Dienst Google AdSense. Dieser
          wird zur Verfügung gestellt durch die Google Inc. (â€žGoogleâ€œ), 1600
          Amphitheatre Parkway, Mountain View, CA 94043, U.S.A.. Google AdSense
          dient dem Einbinden von Werbeanzeigen auf Internetseiten. Dabei werden
          â€žCookiesâ€œ verwendet, kleine Textdateien, die auf Ihrem Computer
          gespeichert werden. Diese Cookies machen es möglich, Ihre Aktivitäten
          auf unserer Internetseite zu verfolgen und zu analysieren, um
          werbliche Maßnahmen darauf abzustimmen. Darüber hinaus verwendet
          Google AdSense für den gleichen Zweck auch sogenannte Web Beacons
          (auch: Pixel Tags genannt). Dies sind nicht sichtbare sehr kleine
          Grafiken. <br />
          <br />
          Die so gewonnenen Daten über Ihre Nutzung unserer Internetseite und
          zum Beispiel auch darüber, welche Werbeanzeigen Sie angeklickt haben,
          werden einschließlich Ihrer IP-Adresse an einen Server von Google in
          den U.S.A. weitergesendet. Dort werden die Daten analysiert und
          gespeichert. Möglicherweise gibt Google die Daten an Dritte wie etwa
          eigene Vertragspartner weiter. Google wird Ihre IP-Adresse nicht mit
          anderen über Sie gesammelten Daten zusammenführen. <br />
          <br />
          Die Speicherung von Cookies auf Ihrem Computer können Sie verhindern,
          indem Sie entsprechende Einstellungen Ihres Browsers durchführen.
          Möglicherweise können Sie dann jedoch nicht alle Funktionen unseres
          Internetangebotes in vollem Umfang nutzen. Eine Datengewinnung mit
          Hilfe von Web Beacons verhindert dies nach unserer Kenntnis nicht.{" "}
          <br />
          <br />
          Durch die Nutzung unserer Internetseiten erklären Sie sich damit
          einverstanden, dass Daten über Ihre Nutzung unserer Internetseite zu
          dem oben beschriebenen Zweck erhoben, gesammelt, ausgewertet und
          genutzt werden.
        </p>
        <h2 className="text-3xl">Datenschutzerklärung Google Plus</h2>
        <p>
          Auf unseren Internetseiten finden Sie die Schaltfläche â€ž+1â€œ des
          Dienstes Google Plus. Dieser wird von der Google Inc., 1600
          Amphitheatre Parkway, Mountain View, CA 94043, U.S.A.. (â€žGoogleâ€œ),
          zur Verfügung gestellt. Die Schaltfläche bzw. den Button von Google
          Plus erkennen Sie an dem Zeichen â€ž+1â€œ auf weißem oder farbigem
          Grund. <br />
          <br />
          Ihnen als Nutzer eröffnet der Dienst Google Plus die Möglichkeit, als
          empfehlenswert empfundene Internetseiten für andere Nutzer als solche
          zu markieren und Ihre Suche bei Google stärker zu personalisieren. Im
          Prinzip handelt es sich um ein soziales Netzwerk, das es Ihnen auch
          ermöglicht, Inhalte weltweit zu veröffentlichen und mit anderen zu
          teilen. <br />
          <br />
          In dem Moment, in dem Sie eine unserer Internetseiten aufrufen, die
          die Schaltfläche â€ž+1â€œ enthält, wird Ihr Browser eine direkte
          Verbindung zu den Servern von Google in den U.S.A. herstellen. Dann
          wird von Google der Inhalt der â€ž+1â€œ-Schaltfläche direkt an Ihren
          Browser gesendet und von diesem in die gerade angezeigte Internetseite
          eingebaut. Wir müssen darauf hinweisen, dass wir keinen Einfluss
          darauf haben, auf welche Weise und in welchem Umfang Google mit Hilfe
          des +1-Button Daten erhebt. <br />
          <br />
          Das Anklicken der â€ž+1â€œ zur Empfehlung einer Internetseite hat zur
          Folge, dass Google Ihre Empfehlung dieser Seite, Informationen über
          die Seite und höchstwahrscheinlich Ihre IP-Adresse speichert. Es ist
          möglich, dass Ihre â€ž+1â€œ-Vergaben in Verbindung mit Ihrem
          Profilnamen und Ihrem Foto in anderen Google-Diensten veröffentlicht
          werden, etwa in Suchergebnissen, Ihrem Google-Profil, in
          Online-Werbeanzeigen oder auf Webseiten. <br />
          <br />
          Die über Ihre â€ž+1â€œ-Klicks gesammelten Daten werden von Google dazu
          verwendet, seine Dienstleistungen zu optimieren. Google kann die Daten
          auch verwenden, um Statistiken über die â€ž+1â€œ-Aktivitäten seiner
          Nutzer zu erstellen. Diese Auswertungen können an Dritte weiter
          gegeben werden, zum Beispiel an Werbekunden oder Betreiber von
          Internetseiten. <br />
          <br />
          Sie können Google Plus nur nutzen, wenn Sie ein Nutzerkonto anlegen
          und ein Google-Profil einrichten. Dieses Profil ist öffentlich und
          weltweit abrufbar. Es muss mit einem Profilnamen versehen werden, der
          üblicherweise für alle Google-Dienste zur Anwendung kommt. Verfügt ein
          anderer Nutzer über Daten, die eine Identifizierung Ihrer Person
          erlauben (z.B. Ihre Email-Adresse), kann er Ihr Google-Profil Ihrer
          Person zuordnen. <br />
          <br />
          Die Erklärungen von Google selbst zum Datenschutz und zu möglichen
          Maßnahmen zum Schutz Ihrer Privatspähre finden Sie unter folgendem
          Link: : <br />
          https://www.google.de/intl/de/policies/terms/regional.html
          <br />
          <br />
          Wenn Sie als Mitglied von â€žGoogle Plusâ€œ unterbinden möchten, dass
          Daten über Ihren Besuch auf unserer Internetseite gesammelt,
          ausgewertet und mit den Daten Ihres Google-Mitgliedskontos verbunden
          werden, sollten Sie sich vor dem Aufrufen unserer Internetseite bei
          â€žGoogle Plusâ€œ ausloggen.
        </p>
        <h2 className="text-3xl">Datenschutzerklärung Twitter</h2>
        <p>
          Auf unseren Internetseiten finden Sie Schaltflächen bzw.Buttons von
          Twitter. Betreiber dieses Dienstes ist die Twitter Inc., 1355 Market
          St., Suite 900, San Francisco, CA 94103, U.S.A. Nutzen Sie Twitter und
          die Funktion â€žRe-Tweetâ€œ, kommt es zu einer direkten
          Datenübertragung zwischen Ihrem Browser und den Servern von Twitter.
          Twitter verknüpft die besuchte Internetseite mit Ihrem Nutzerkonto bei
          Twitter. Dadurch können andere Nutzer erkennen, dass Sie unsere
          Internetseiten besucht haben. <br />
          <br />
          Wir weisen darauf hin, dass wir keine Kenntnis darüber oder gar
          Einfluss darauf haben, wie Twitter die gesammelten Daten nutzt. Eine
          Erklärung von Twitter selbst zum Thema Datenschutz und Umgang mit
          Ihrer Privatsphäre finden Sie unter folgendem Link: : <br />
          http://twitter.com/privacy
          <br />
          <br />
          Twitter ermöglicht Ihnen durch die Vornahme eigener Einstellungen auf
          Ihrem Twitter-Account, den Schutz Ihrer Privatsphäre zu verbessern.
          Nutzen Sie dazu den folgenden Link: <br />
          http://twitter.com/account/settings
        </p>
        <h2 className="text-3xl">Disclaimer</h2>
        <p>
          <strong>Haftung für Inhalte unserer Internetseiten</strong>
          <br />
          <br />
          Die Inhalte unserer Internetseiten werden mit der größtmöglichen
          Sorgfalt erstellt. Wir können jedoch keine Garantie für Richtigkeit,
          Vollständigkeit und Aktualität geben. Mit dem Namen eines Autors
          versehene Beiträge geben die Meinung dieses Autors wieder, die nicht
          mit der Meinung des Anbieters übereinstimmen muss. <br />
          <br />
          Gemäß Â§ 7 Abs.1 Telemediengesetz (TMG) sind wir als Diensteanbieter
          für eigene Inhalte auf unseren Internetseiten im Rahmen der
          allgemeinen Gesetze verantwortlich. <br />
          Nach den Â§Â§ 8 bis 10 TMG sind wir nicht verpflichtet, fremde
          Informationen zu überwachen, die von uns nur durchgeleitet,
          übermittelt oder gespeichert werden oder aktiv nach Hinweisen auf
          rechtswidrige Tätigkeiten zu suchen. Eine Ausnahme besteht dann, wenn
          wir auf Grund gesetzlicher Vorschriften zur Entfernung oder Sperrung
          von Inhalten bzw. Informationen sowie zur Sperrung von
          Nutzungsmöglichkeiten unserer Internetseiten verpflichtet sind. Wir
          haften erst von dem Zeitpunkt an, zu dem wir von einer
          Rechtsverletzung Kenntnis erhalten haben. Sobald wir von einer
          Rechtsverletzung erfahren, werden wir die betreffenden Inhalte so
          schnell wie möglich von unseren Internetseiten entfernen. <br />
          <br />
          <strong>Haftung für Links</strong>
          <br />
          <br />
          Auf unseren Internetseiten finden Sie Links zu externen Webseiten
          fremder Seitenbetreiber. Beim Setzen der Verlinkung haben wir geprüft,
          ob auf den verlinkten Seiten Rechtsverstöße erkennbar waren. Verstöße
          wurden nicht festgestellt. Wir können jedoch keine ständige Kontrolle
          verlinkter Internetseiten durchführen. Bitte nehmen Sie zur Kenntnis,
          dass wir auf den Inhalt von verlinkten Seiten anderer Anbieter
          keinerlei Einfluss besitzen. Allein deren Betreiber ist für deren
          Inhalt verantwortlich. Wir können daher für die Inhalte verlinkter
          Seiten keinerlei Haftung übernehmen. <br /> <br />
          Erhalten wir konkrete Hinweise auf eine Rechtsverletzung auf
          verlinkten Internetseiten, werden wir den Fall prüfen und den Link bei
          Vorliegen einer Rechtsverletzung so schnell wie möglich entfernen.{" "}
          <br />
          <br />
          <strong>Urheberrecht</strong>
          <br />
          <br />
          Für die auf unseren Internetseiten veröffentlichten Inhalte und Werke
          aller Art gilt das deutsche Urheberrecht. Jede Bearbeitung,
          Vervielfältigung, Verbreitung oder Verwertung darf nur mit
          schriftlicher Zustimmung des jeweiligen Urhebers (z.B. Autor,
          Fotograf) bzw. Rechteinhabers stattfinden. Downloads und Kopien dürfen
          nur für private, nicht kommerzielle Zwecke vorgenommen werden. <br />
          <br />
          Soweit unsere Internetseiten Beträge Dritter wie Fotos oder Texte
          enthalten, beachten wir deren Urheberrecht und kennzeichnen die
          jeweiligen Beiträge entsprechend. Finden Sie auf unseren Seiten einen
          Verstoß gegen das Urheberrecht, bitten wir um Mitteilung. Wir werden
          den entsprechenden Inhalt dann so schnell wie möglich von unserer
          Seite entfernen.
        </p>
      </div>
    </>
  );
};

export default Impressum;
